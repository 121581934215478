import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import React, { useEffect, useMemo, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import Nav from "../components/nav";
import ProjectDetails from "../components/projectdetail";
import ProjectOverview, { projectTypeDesc } from "../components/projectOverview";
import Sponsor from "../components/sponsor";
import Select, { ActionMeta } from "react-select";
import { ErrorBoundary } from "react-error-boundary";
import { ProjectList } from "../generated/src/api/proto/api";
import { PubAPI } from "../api";

export interface FilterType {
  projectyear: SelectType[];
  projecttype: number[];
}

export interface SelectType {
  value: string;
  label: string;
}

export const ProjectRoutes = () => {
  let match = useRouteMatch();
  return (
    <>
      <Nav />
      <Switch>
        <Route path={`${match.path}/:projectID`}>
          <ProjectDetails />
        </Route>
        <Route path={match.path}>
          <Projects />
        </Route>
      </Switch>
      <Sponsor></Sponsor>
    </>
  );
};

const Projects = () => {
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<FilterType>({
    projectyear: [
      {
        /* value: DateTime.now().year.toString(), */
        /* label: DateTime.now().year.toString(), */
        value: "2024",
        label: "2024",
      },
    ],
    projecttype: [],
  });

  const [project, setProject] = useState<ProjectList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjects({});
        if (!ignore) {
          setProject(res.projects);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const fuse = useMemo(
    () =>
      new Fuse(project, {
        keys: ["Title", "Description"],
      }),
    [project]
  );

  let projectResult =
    search === "" ? project : fuse.search(search).map((p) => p.item);

  const tempProjectyear = projectResult.map((U) => {
    return U.Year.toString();
  });

  function onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  tempProjectyear.push(new Date().getFullYear().toString());
  const projectyearFilter: SelectType[] = tempProjectyear
    .filter(onlyUnique)
    .sort()
    .map((y) => ({ value: y, label: y }));

  if (filter.projectyear.length !== 0) {
    projectResult = projectResult.filter((u) =>
      filter.projectyear.map((y) => y.value).includes(u.Year.toString())
    );
  }

  const projectTypeFilterOptions = projectResult.map(p => p.ProjectType).filter(onlyUnique).sort();

  if (filter.projecttype.length > 0) {
    projectResult = projectResult.filter((u) =>
      filter.projecttype.includes(u.ProjectType)
    );
  }

  return (
    <>
      <Title
        search={search}
        setSearch={setSearch}
        setFilter={setFilter}
        projectyearFilter={projectyearFilter}
        projectTypeFilter={projectTypeFilterOptions}
        filter={filter}
      />
      <ProjectOverview projects={projectResult} />
    </>
  );
};

const Jumbotron = styled.section`
  background-color: #fff;
  margin-bottom: 0;
`;

const Searchbar = styled.div`
  padding-left: 15px;
  max-width: 800px;
  margin-top: 30px;
`;

const SearchInput = styled.input`
  border: 0;
  height: 50px;
  border-radius: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  font-size: 1em;
  font-weight: 700;
  :active {
    box-shadow: none !important;
    outline: none;
  }
  :focus {
    box-shadow: none !important;
    outline: none;
  }
`;

const SearchIcon = styled.a`
  font-size: 1.5rem;
`;

function Title(props: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  filter: FilterType;
  projectyearFilter: SelectType[];
  projectTypeFilter: number[];
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}) {
  const [showResults, setShowResults] = React.useState(true);
  const showFilter = () => setShowResults(!showResults);
  return (
    <Jumbotron className="jumbotron text-center">
      <div className="container">
        <h1>Projektübersicht</h1>
        <p className="lead text-muted">
          Staune und informiere dich über die vielfältigen Projekte.
        </p>
        <ErrorBoundary fallbackRender={() => <></>}>
          <Searchbar className=" mx-auto row align-items-center border border-primary rounded-pill">
            <SearchIcon className="col-auto">
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <div className="col">
              <SearchInput
                className=""
                type="search"
                placeholder="Suche nach Projekttitel, Stichworten, ..."
                value={props.search}
                onChange={(i) => props.setSearch(i.currentTarget.value)}
              />
            </div>
            <SearchIcon className="col-auto">
              <FontAwesomeIcon icon={faFilter} onClick={showFilter} />
            </SearchIcon>
          </Searchbar>
          {showResults && (
            <Results
              setFilter={props.setFilter}
              projectyearFilter={props.projectyearFilter}
              projectTypeFilter={props.projectTypeFilter}
              filter={props.filter}
            />
          )}
        </ErrorBoundary>
      </div>
    </Jumbotron>
  );
}

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
`;

const FilterItem = styled.div`
  margin: 10px;
  width: 200px;
`;

const Results = (props: {
  projectyearFilter: SelectType[];
  projectTypeFilter: number[];
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}) => {
  return (
    <FilterBox className="mx-auto row align-items-center">
      <FilterItem>
        <Select
          closeMenuOnSelect={true}
          placeholder="Jahr"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              projectyear: [...option],
            }));
          }}
          isMulti
          options={props.projectyearFilter}
          defaultValue={[
            {
              /* value: DateTime.now().year.toString(), */
              /* label: DateTime.now().year.toString(), */
              value: "2024",
              label: "2024",
            },
          ]}
        />
      </FilterItem>
      <FilterItem>
        <Select
          closeMenuOnSelect={true}
          placeholder="Projekttyp"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              projecttype: option.map(x => parseInt(x.value)),
            }));
          }}
          isMulti
          options={props.projectTypeFilter.map(x => ({ label: projectTypeDesc[x], value: x.toString() }))}
        />
      </FilterItem>
    </FilterBox>
  );
};

export default Projects;
