import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ProjectList, Image } from "../generated/src/api/proto/api";
import { ImageComponent } from "./image";

function ProjectOverview(props: { projects: ProjectList[] }) {
  const projects = props.projects;

  return (
    <div className="album py-5 bg-light">
      <div className="container">
        <div className="row">
          {projects.map((proj) => {
            return (
              <ProjectCard
                title={proj.Title}
                img={proj.CoverImage}
                projectID={proj.ID}
                key={proj.ID}
                year={proj.Year}
                typ={proj.ProjectType}
              ></ProjectCard>
            );
          })}
        </div>
      </div>
    </div>
  );
}

const ImgOverlay = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0px;
  padding: 4px 14px 4px 15px;
  border-radius: 0px 20px 20px 0px;
  color: white;
  background: #57b846;
`;

const TypeOverlay = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0px;
  padding: 4px 14px 4px 15px;
  border-radius: 0px 20px 20px 0px;
  color: white;
  background: #57b846;
`;

const ProjectTitle = styled.h5`
  height: 2.4em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const projectTypeDesc = [
  "Unbekannt",
  "Einzelarbeit",
  "Gruppenarbeit",
  "Spezialfall",
  "BruefsLAB",
]

export const ProjectCard = (props: {
  title: string;
  img: Image | undefined;
  projectID: string;
  year: number;
  typ: number;
}) => {
  return (
    <div className="col-md-4">
      <div className="card mb-4 shadow-sm" style={{ height: "350px" }}>
        {props.img !== undefined && props.img.BaseURL !== "" ? (
          <div style={{ height: "225px", flexShrink: "0" }}>
            <div style={{ position: "relative" }}>
              <ImageComponent
                img={props.img}
                customMaxHeight={"225px"}
                fill
                objectFit="cover"
              />
              <TypeOverlay>{projectTypeDesc[props.typ]}</TypeOverlay>
              <ImgOverlay>{props.year}</ImgOverlay>
            </div>
          </div>
        ) : (
          <div style={{ position: "relative" }}>
            <svg
              className="bd-placeholder-img card-img-top"
              width="100%"
              height="225"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
              role="img"
              aria-label="Placeholder: Thumbnail"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#55595c"></rect>
            </svg>
            <TypeOverlay>{projectTypeDesc[props.typ]}</TypeOverlay>
            <ImgOverlay>{props.year}</ImgOverlay>
          </div>
        )}
        <div className="card-body">
          <ProjectTitle className="card-title">{props.title}</ProjectTitle>
          <Link
            to={`/projects/${props.projectID}`}
            className="btn btn-primary btn-sm stretched-link"
          >
            Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectOverview;
