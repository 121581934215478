import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../components/nav";
import Sponsor from "../components/sponsor";
import { ButtonGroup, Table, ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnDef,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  faExternalLinkAlt,
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ProjectList, User, UserList } from "../generated/src/api/proto/api";
import { GetProfessionName, PubAPI } from "../api";
import { projectTypeDesc } from "../components/projectOverview";

const groupByYear = <T extends { Year?: number }>(
  data: T[]
): Map<number, T[]> => {
  const groupedData = new Map<number, T[]>();
  data.forEach((item) => {
    const year = item.Year || 0;
    if (!groupedData.has(year)) {
      groupedData.set(year, []);
    }
    groupedData.get(year)?.push(item);
  });
  return groupedData;
};

const YearHeader = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #dee2e6;
`;

const MixView = () => {
  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: "Lernende", value: "1" },
    { name: "Projekte", value: "2" },
  ];

  return (
    <>
      <Nav />
      <div className="container py-4">
        <h1>Mix-Ansicht</h1>
        <p className="lead text-muted">
          Mache dir ein Bild über die verschiedenen Projekte und Lernenden.
        </p>
        <ButtonGroup toggle className="mb-4">
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="radio"
              variant="success"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        {radioValue === "1" ? (
          <UserProjectTableWrapper />
        ) : (
          <ProjectUserTableWrapper />
        )}
      </div>
      <Sponsor />
    </>
  );
};

const BreakWord = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 310px;
  display: block;
  overflow: hidden;
`;

interface ProjectUserList {
  Projects: ProjectList;
  Users: (User | undefined)[];
}

const ProjectUserTableWrapper = () => {
  const [data, setData] = useState<ProjectUserList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetUsers({});
        if (!ignore) {
          const dataMap = new Map<string, ProjectUserList>();
          res.users.forEach((u) => {
            if (u.Projects?.ID !== undefined) {
              dataMap.set(u.Projects.ID, {
                Projects: u.Projects,
                Users: dataMap.has(u.Projects.ID)
                  ? [...dataMap.get(u.Projects.ID)!.Users, u.Users]
                  : [u.Users],
              });
            }
          });
          let dataArray = Array.from(dataMap.values());
          // sort by internal project number
          dataArray.sort((a, b) =>
            a.Projects.InternalProjectNumber.localeCompare(
              b.Projects.InternalProjectNumber
            )
          );
          setData(dataArray);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const groupedByYear = React.useMemo(() => {
    const groups = new Map<number, ProjectUserList[]>();
    data.forEach((item) => {
      const year = item.Projects.Year || 0;
      if (!groups.has(year)) {
        groups.set(year, []);
      }
      groups.get(year)?.push(item);
    });
    return new Map([...groups.entries()].sort((a, b) => b[0] - a[0]));
  }, [data]);

  return (
    <>
      {Array.from(groupedByYear.entries()).map(([year, projects]) => (
        <ProjectUserTable key={year} data={projects} year={year.toString()} />
      ))}
    </>
  );
};

interface ProjectUserTableProps {
  data: ProjectUserList[];
  year: string;
}

const ProjectUserTable: React.FC<ProjectUserTableProps> = ({ data, year }) => {
  const columnHelper = createColumnHelper<ProjectUserList>();

  const columns = React.useMemo<ColumnDef<ProjectUserList, any>[]>(
    () => [
      columnHelper.group({
        header: "Projekt",
        columns: [
          columnHelper.accessor((row) => row.Projects.InternalProjectNumber, {
            header: "Stand Nr.",
          }),
          columnHelper.accessor((row) => row.Projects.Title, {
            header: "Titel",
            cell: ({ getValue, row: { original } }) => {
              if (getValue()?.length < 22) {
                return (
                  <Link to={`/projects/${original.Projects.ID}`}>
                    {getValue()}
                  </Link>
                );
              } else {
                return (
                  <Link to={`/projects/${original.Projects.ID}`}>
                    <BreakWord>{getValue()}</BreakWord>
                  </Link>
                );
              }
            },
          }),
          // columnHelper.accessor((row) => row.Projects.Year, {
          //   header: "Jahr",
          // }),
          columnHelper.accessor((row) => row.Projects.ProjectType, {
            header: "Type",
            cell: ({ getValue, row: { original } }) => {
              return <>{projectTypeDesc[getValue() as number]}</>;
            },
          }),
        ],
      }),
      columnHelper.group({
        header: "Lernende",
        enableSorting: false,
        columns: [
          columnHelper.accessor((row) => row, {
            header: "Name",
            cell: ({ getValue, row: { original } }) => {
              const a = original.Users.map((u) => {
                if (u === undefined) {
                  return <></>;
                }
                return (
                  <li>
                    <Link to={`/projects/${original.Projects.ID}`}>
                      {u.FirstName} {u.LastName}
                    </Link>
                  </li>
                );
              });
              return <ul className="list-unstyled ">{a}</ul>;
            },
          }),
          columnHelper.accessor((row) => row.Users, {
            header: "Beruf",
            cell: ({ row: { original } }) => {
              const a = original.Users.map((u) => {
                if (u === undefined) {
                  return <></>;
                }
                return (
                  <li>
                    <a
                      href={`https://berufsberatung.ch/dyn/show/1900?id=${u.Profession?.ExternalID}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        className="text-primary mr-2"
                        icon={faExternalLinkAlt}
                      ></FontAwesomeIcon>
                      {GetProfessionName(u)}
                    </a>
                  </li>
                );
              });
              return <ul className="list-unstyled ">{a}</ul>;
            },
          }),
        ],
      }),
    ],
    [columnHelper]
  );

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "Stand Nr.",
      desc: false,
    },
  ]);

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div key={year}>
        <YearHeader>{year}</YearHeader>
        <Table striped bordered hover size="sm">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          cursor: header.column.getCanSort()
                            ? "pointer"
                            : "auto",
                          userSelect: "none",
                          whiteSpace: "nowrap",
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort()
                          ? {
                              asc: (
                                <FontAwesomeIcon
                                  className="text-secondary ml-1 mr-1"
                                  icon={faSortDown}
                                />
                              ),
                              desc: (
                                <FontAwesomeIcon
                                  className="text-secondary ml-1 mr-1"
                                  icon={faSortUp}
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? (
                              <FontAwesomeIcon
                                className="text-secondary ml-1 mr-1"
                                icon={faSort}
                              />
                            )
                          : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const UserProjectTableWrapper = () => {
  const [data, setData] = useState<UserList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetUsers({});
        if (!ignore) {
          setData(res.users);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const groupedByYear = React.useMemo(() => {
    const groups = new Map<number, UserList[]>();
    data.forEach((user) => {
      const year = user.Projects?.Year || 0;
      if (!groups.has(year)) {
        groups.set(year, []);
      }
      groups.get(year)?.push(user);
    });
    return new Map([...groups.entries()].sort((a, b) => b[0] - a[0]));
  }, [data]);

  return (
    <>
      {Array.from(groupedByYear.entries()).map(([year, users]) => (
        <UserProjectTable key={year} data={users} year={year.toString()} />
      ))}
    </>
  );
};

interface UserProjectTableProps {
  data: UserList[];
  year: string;
}

const UserProjectTable: React.FC<UserProjectTableProps> = ({ data, year }) => {
  const columnHelper = createColumnHelper<UserList>();

  const columns = React.useMemo<ColumnDef<UserList, any>[]>(
    () => [
      columnHelper.group({
        header: "Lernende",
        columns: [
          columnHelper.accessor((row) => row.Users?.FirstName, {
            header: "Name",
            cell: ({ getValue, row: { original } }) => (
              <Link to={`/projects/${original.Projects?.ID}`}>
                {getValue()} {original.Users?.LastName}
              </Link>
            ),
          }),
          columnHelper.accessor((row) => row.Users?.Profession?.FemaleTitle, {
            header: "Beruf",
            cell: ({ row: { original } }) => (
              <a
                href={`https://berufsberatung.ch/dyn/show/1900?id=${original.Users?.Profession?.ExternalID}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="text-primary mr-2"
                  icon={faExternalLinkAlt}
                ></FontAwesomeIcon>
                {GetProfessionName(original.Users)}
              </a>
            ),
          }),
        ],
      }),
      columnHelper.group({
        header: "Projekt",
        columns: [
          columnHelper.accessor((row) => row.Projects?.Title, {
            header: "Titel",
            cell: ({ getValue, row: { original } }) => {
              if (getValue()?.length < 22) {
                return (
                  <Link to={`/projects/${original.Projects?.ID}`}>
                    {getValue()}
                  </Link>
                );
              } else {
                return (
                  <Link to={`/projects/${original.Projects?.ID}`}>
                    <BreakWord>{getValue()}</BreakWord>
                  </Link>
                );
              }
            },
          }),
          // columnHelper.accessor((row) => row.Projects?.Year, {
          //   header: "Jahr",
          //   cell: ({ getValue, row: { original } }) => {
          //     return <>{getValue()}</>;
          //   },
          // }),
        ],
      }),
    ],
    [columnHelper]
  );

  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "Name",
      desc: false,
    },
  ]);

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div key={year}>
        <YearHeader>{year}</YearHeader>
        <Table striped bordered hover size="sm">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        style={{
                          cursor: header.column.getCanSort()
                            ? "pointer"
                            : "auto",
                          userSelect: "none",
                          whiteSpace: "nowrap",
                        }}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort()
                          ? {
                              asc: (
                                <FontAwesomeIcon
                                  className="text-secondary ml-1 mr-1"
                                  icon={faSortDown}
                                />
                              ),
                              desc: (
                                <FontAwesomeIcon
                                  className="text-secondary ml-1 mr-1"
                                  icon={faSortUp}
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? (
                              <FontAwesomeIcon
                                className="text-secondary ml-1 mr-1"
                                icon={faSort}
                              />
                            )
                          : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MixView;
