import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PubAPI } from "../api";
import { ProjectList } from "../generated/src/api/proto/api";
import { ProjectCard } from "./projectOverview";

function Besucher() {
  const [project, setProject] = useState<ProjectList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjects({
          limit: 3,
          year: 2024,
          withCoverImage: true,
          projectType: [1, 2, 3], // Einzelarbeit = 1, Gruppenarbeit = 2, Spezialfall = 3
        });
        if (!ignore) {
          setProject(res.projects);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="container mb-5">
      <h1>Willkommen auf LWZO Online?!</h1>
      <br></br>
      <h2>So funktionierts...</h2>
      <p className="lead text-muted">
        Staune über die vielfältigen Projekte, welche mit Bildern, Videos und
        Dokumenten vorgestestellt werden.
      </p>

      <div className="album py-2">
        <div className="container">
          <div className="row">
            {project.map((proj) => {
              return (
                <ProjectCard
                  title={proj.Title}
                  img={proj.CoverImage}
                  projectID={proj.ID}
                  key={proj.ID}
                  year={proj.Year}
                  typ={proj.ProjectType}
                ></ProjectCard>
              );
            })}
          </div>
        </div>
      </div>

      <Link to="/projects" className="btn btn-primary my-2 mx-2">
        Hier geht es zu allen anderen Projekten
      </Link>
      <br></br>
      <br></br>
      <p className="lead text-muted">
        Finde Lernende via Lehrberuf, Lehrfirma, Name.
      </p>
      <Link to="/persons" className="btn btn-primary my-2 mx-2">
        Zu den Lernenden
      </Link>
      <br></br>
      <br></br>
      <p className="lead text-muted">Mache dir eine Übersicht.</p>
      <Link to="/overview" className="btn btn-primary my-2 mx-2">
        Zur Mix-Ansicht
      </Link>
    </div>
  );
}

export default Besucher;

export const Kontakt = () => {
  return (
    <div className="container mb-5">
      <h2>Kontakt</h2>
      <p>
        Die Geschäftsstelle des Lehrlingswettbewerb Züri-Oberland unterstützt
        dich gerne bei anderen Fragen.
        <br></br>
        Melde dich bei via <a href="mailto:info@lwzo.ch">info@lwzo.ch</a>
      </p>
    </div>
  );
};
